import { Injectable } from '@angular/core';
import moment from 'moment';
import {NativeDateAdapter} from '@angular/material/core';
import { CustomValidators } from '../core/util/custom-validators';

const formats = {
    en: 'DD/MM/YYYY',
    de: 'DD.MM.YYYY'
};

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    },
    display: {
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'short'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'short'},
    }
};

@Injectable({
    providedIn: 'root',
})
export class AppDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
            value = CustomValidators.checkDateStringContent(value);
            const str = value.split('.');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'input') {
            return moment(date).format(formats['de']);
        } else {
            return moment(date).format('LL');
        }
    }

}
